:root {
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;

  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 14px;

  /* Helper variables to avoid duplication in the colors. */
  --ck-custom-background: #1f1f1f;
  --ck-custom-foreground: #fff;
  --ck-custom-border: #373737;
  --ck-custom-white: hsl(0, 0%, 100%);
  --ck-focus-ring: #373737;
  --ck-color-base-background: #2b2b2b;
  --ck-color-base-border: #373737;
  --ck-color-focus-outer-shadow: #454648;

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: #454648;
  --ck-color-text: var(--ck-custom-foreground);
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: #2b2b2b;
  --ck-color-button-default-active-background: var(--ck-color-button-default-hover-background);
  --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--ck-color-button-default-hover-background);
  --ck-color-button-on-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-button-on-active-background: var(--ck-color-button-default-hover-background);
  --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
  --ck-color-button-on-disabled-background: #c0c0c0;
  --ck-color-button-on-color: #fff;

  --ck-color-button-action-background: var(--ck-custom-background);
  --ck-color-button-action-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-button-action-active-background: var(--ck-color-button-default-hover-background);
  --ck-color-button-action-active-shadow: #454648;
  --ck-color-button-action-disabled-background: #c0c0c0;
  --ck-color-button-action-text: var(--ck-custom-white);

  --ck-color-button-save: var(--ck-color-button-default-hover-background);
  --ck-color-button-cancel: hsl(15, 100%, 56%);

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

  --ck-color-dropdown-panel-background: #2b2b2b;
  --ck-color-dropdown-panel-border: #373737;

  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-split-button-hover-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: #373737;
  --ck-color-input-text: #fff;
  --ck-color-input-disabled-background: #c0c0c0;
  --ck-color-input-disabled-border: #373737;
  --ck-color-input-disabled-text: #c0c0c0;

  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

  --ck-color-labeled-field-label-background: var(--ck-custom-background);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: var(--ck-color-input-disabled-border);
  --ck-color-list-button-on-background: var(--ck-color-input-disabled-border);
  --ck-color-list-button-on-background-focus: var(--ck-color-input-disabled-border);
  --ck-color-list-button-on-text: var(--ck-color-input-text);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

  --ck-color-tooltip-background: hsl(252, 7%, 14%);
  --ck-color-tooltip-text: hsl(0, 0%, 93%);

  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);

  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

  --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
  --ck-color-widget-hover-border: hsl(43, 100%, 68%);
  --ck-color-widget-editable-focus-background: var(--ck-custom-white);

  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

  --ck-color-link-default: #454648;
  --ck-color-button-save: #fff;

  --plyr-video-control-background-hover: var(--hms-ui-colors-primaryLight);
  --plyr-progress-marker-background: #e9e9e9;
  /* --plyr-font-size-small: 20px */
}

*,
*::after,
*::before{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131313;
  color: #E9E9E9;
  font-size: 16px;
  line-height: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.MuiModal-root .MuiPaper-elevation{
  background-color: #1F1F1F !important;
  border: 1px solid #373737 !important;
  box-shadow: none !important;
  margin-top: 5px;
}

.MuiModal-root .MuiPaper-elevation .MuiMenu-list{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.MuiModal-root .MuiPaper-elevation .MuiMenuItem-root{
  padding: 10px 12px;
}

.MuiModal-root .MuiPaper-elevation .MuiMenuItem-root,
.MuiModal-root .MuiPaper-elevation .MuiMenuItem-root .MuiListItemIcon-root{
  color: #C0C0C0;
}

.MuiModal-root .MuiPaper-elevation .MuiMenuItem-root .MuiListItemIcon-root{
  width: 18px;
  height: 18px;
}

.MuiModal-root .MuiPaper-elevation .MuiMenuItem-root.Mui-selected,
.MuiModal-root .MuiPaper-elevation .MuiMenuItem-root:hover{
  background-color: #2B2B2B;
  color: #fff;
}

.MuiPaper-elevation > ul,
.app-content-dropdown ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.app-items-title{
  text-transform: uppercase;
  color: #8C8E95;
  margin-top: 0;
  margin-bottom: 20px;
}

.app-content-dropdown{
  padding: 10px 24px;
}

.app-content-dropdown ul a{
  display: block;
  text-decoration: none;
  color: #C0C0C0;
  font-size: 15px;
  line-height: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

[aria-labelledby="Modal-add-source"] .idp-modal-body{
  width: 640px;
}

#header-language-menu .MuiPaper-root.MuiPaper-elevation{
  background-color: #1f1f1f !important;
  border-color: #1f1f1f !important;
  box-shadow: 0 0 10px rgba(0,0,0,0.4) !important;
  margin-top: 0;
  border-radius: 11px;
  font-weight: 700;
  color: #e9e9e9;
  width: 500px;
  max-height: 300px;
}

#header-language-menu .header-language-box-menu-inner{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

#header-language-menu .MuiMenuItem-root{
  font-size: 14px;
  line-height: 1.35;
  word-break: break-word;
  white-space: normal;
  width: calc(50% - 3px);
}

#header-language-menu .MuiMenuItem-root:hover{
  background-color: #2b2b2b;
  color: #fff;
}

.header-language-box-top{
  color: #c0c0c0;
  font-size: 10px;
  gap: 6px;
  font-weight: 600;
  min-width: 1px;
  background-color: transparent;
  padding: 3px !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  text-align: center;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  letter-spacing: normal;
  box-shadow: none;
}

.header-language-box-top:hover{
  color: #e9e9e9;
  background-color: #1f1f1f;
  border-color: #E3C050;
  box-shadow: none;
}

.header-language-box-top > .MuiSvgIcon-root{
  width: 18px;
  height: 19px;
}

.header-language-box > .MuiSvgIcon-root{
  color: #e9e9e9;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

@media (min-width: 576px){
  #header-language-menu .MuiMenuItem-root{
    width: calc(33.333% - 4px);
  }
}
@media (min-width: 768px){
  .header-language-box-top{
    height: 50px !important;
    width: 50px !important;
  }
}

body{
  top: 0 !important;
}
.userLogin {
  &-social {
    iframe {
      width: 100% !important;
    }

    &-btn {
      padding: 10px 20px;
      text-align: center;
      border: 1px solid #373737;
      border-radius: 6px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      height: 48px;
      color: #fff;
      background: transparent;

      img {
        margin-right: 10px;
      }

      &:active,
      &:hover {
        background-color: #373737;
      }
    }
  }

  &-or {
    position: relative;
    text-align: center;
    color: #575757;
    font-size: 14px;
    margin: 25px 0px;

    &-text {

      &::before,
      &::after {
        content: "";
        height: 1px;
        background-color: #373737;
        position: absolute;
        top: 50%;
        width: 45%;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }

  &-email {
    width: 100%;

    &-input {
      background-color: #131313 !important;
      color: #fff !important;
      border: 1px solid #373737 !important;
      border-radius: 8px !important;
      outline: none !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      height: 46px;
      resize: none;
      padding: 0.5rem 0.75rem;
      margin-bottom: 20px;
      width: 100%;
    }


    &-btn {
      padding: 10px 20px;
      text-align: center;
      border: 1px solid #e3c050;
      border-radius: 6px;
      background-color: #000;
      color: #fff;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      height: 48px;
      text-transform: uppercase;

    }

    &-msg {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      min-height: 48px;
    }

    &-link {
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;

      &:hover {
        color: #e3c050;
      }
    }
  }
}

.sixPromoPopup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(19, 19, 19, 0.7);
  backdrop-filter: blur(10px);
  overflow: auto;

  &-close {
    position: absolute;
    cursor: pointer;
    right: 0px;
    top: 0;
    padding: 10px 15px;
  }

  &-inner {
    position: relative;
    color: #fff;
    gap: 10px;
    width: 1000px;
    background: #2b2b2b;
    border: 1px solid #454648;
    border-radius: 6px;
    padding: 24px 24px;
    max-width: 95%;
    margin: 40px auto;


  }

  &-sm & {
    &-inner {
      width: 500px;
    }
  }

  &-header {
    &-heading {
      color: #E9E9E9;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }

    &-desc {
      color: #8C8E95;
      font-size: 14px;
      margin-top: 10px;
      text-align: center;
    }
  }

  &-content {
    width: 100%;
    padding: 20px 0px;
  }

  &-footer {
    &-btn {
      gap: 16px;

      &.btn-grey {
        width: 194px;
        height: 48px;
        color: #fff;
        border: 1px solid #454648;
        border-radius: 6px;

        &:active {
          background-color: #373737;
        }
      }

      &.btn-gold {
        width: 194px;
        height: 48px;
        color: #fff;
        border: 1px solid #e3c050;
        border-radius: 6px;
        background-color: #121212;
      }
    }

  }
}

.sixPromoLoader {
  position: fixed;
  top: 0px;
  bottom: 0px;
  align-items: center;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;
  z-index: 999999999;
}